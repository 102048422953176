import { Logo } from "@components/Logo"
import { Button } from "@components/ui/button"
import { Container } from "@components/ui/Container"
import { Span } from "@components/ui/typograhpy/Span"
import Link from "next/link"
import { FC } from "react"
import { AnimationVariant } from "../layout"
import * as styles from "./styles"
import { ContentPageHeaderMobileMenu } from "./MobileMenu"

export const internalLinks = [
  {
    href: "/pricing",
    title: "Pricing",
  },
]

type ContentPageHeaderProps = {
  animationVariant: AnimationVariant
}

export const ContentPageHeader: FC<ContentPageHeaderProps> = ({
  animationVariant,
}) => {
  return (
    <>
      <div className={styles.spacer} />
      <nav className={styles.parent} data-animation-variant={animationVariant}>
        <Container className={styles.container} as="div">
          <Link href="/" passHref>
            <a className={styles.logo}>
              <Logo variant="full" />
              <Logo variant="icon-only" />
            </a>
          </Link>
          <div className={styles.desktopNav}>
            <Span
              as="a"
              href="https://docs.viesus.cloud"
              target="blank"
              variant="button-label-small"
            >
              Docs
            </Span>
            {internalLinks.map((link) => (
              <Link href={link.href} passHref key={link.href}>
                <Span as="a" variant="button-label-small">
                  {link.title}
                </Span>
              </Link>
            ))}
          </div>
          <div className={styles.buttons}>
            <Link href="/login" passHref>
              <Span as="a" variant="button-label-small">
                Login
              </Span>
            </Link>
            <Link href="/signup/form" passHref>
              <Button as="link" variant="primary" size="small">
                Try for free
              </Button>
            </Link>
            <ContentPageHeaderMobileMenu />
          </div>
        </Container>
      </nav>
    </>
  )
}
