import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rgba } from "polished";
export const link = parse({
  fontWeight: "semiBold",
  color: "shade500",
  display: "flex",
  alignItems: "center"
}, "lw9316v");
export const icon = parse({
  display: "flex",
  alignItems: "center",
  mt: "4",
  ml: "4"
}, "io5gy81");

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");