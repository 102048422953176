import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconChevronRightFat: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.869 17.66a2.5 2.5 0 000-3.32l-8-9-3.737 3.32L17.654 16l-6.524 7.34 3.737 3.32 8-9z"
        clipRule="evenodd"
      ></path>
    </Icon>
  )
}

export default IconChevronRightFat
