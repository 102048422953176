import React, { FC } from "react"
import { Icon, IconProps } from "./Icon"

export const IconExternalFat: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.669 22.68l-1.09-8.723-11.235 11.235a2.5 2.5 0 11-3.536-3.535l11.235-11.235-8.722-1.09.62-4.962 13.794 1.725a2.5 2.5 0 012.17 2.17L27.63 22.06l-4.961.62z"
        clipRule="evenodd"
      ></path>
    </Icon>
  )
}

export default IconExternalFat
