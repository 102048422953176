import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
export const reveal = parse({
  position: "fixed",
  display: "block"
}, "r1enw7ou");
export const content = parse({
  display: "block"
}, "c1qh2msx");

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");