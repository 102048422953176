import { SanitySeo } from "@lib/sanity/seo-type"
import { NextSeoProps } from "next-seo"
import { useRouter } from "next/router"
import { FC, useEffect, useState } from "react"
import { FooterSingleton } from "__generated__/sanity"
import { ContentPageFooter } from "../footer"
import { ContentPageHeader } from "../header"
import { ContentPageSeo } from "../SEO"
import * as styles from "./styles"

type ContentPageLayoutProps = {
  footer: FooterSingleton
  seo: NextSeoProps & SanitySeo
}
export type AnimationVariant = "animate" | "visible" | "hidden"

export const ContentPageLayout: FC<ContentPageLayoutProps> = ({
  children,
  footer,
  seo,
}) => {
  const { isReady } = useRouter()
  const [animationVariant, setAnimationVariant] =
    useState<AnimationVariant>("animate")

  useEffect(() => {
    // Disabled for testing 👇
    // setAnimationVariant("visible")
    setAnimationVariant("animate")
  }, [isReady])

  return (
    <>
      <ContentPageSeo {...seo} />
      <div>
        <ContentPageHeader animationVariant={animationVariant} />
        <div
          className={styles.content}
          data-animation-variant={animationVariant}
        >
          {children}
        </div>
        <span
          className={styles.reveal}
          data-animation-variant={animationVariant}
        />
        <ContentPageFooter {...footer} />
      </div>
    </>
  )
}
