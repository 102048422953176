import { urlFor } from "@lib/sanity"
import { SanitySeo } from "@lib/sanity/seo-type"
import { NextSeo, NextSeoProps } from "next-seo"
import { OpenGraphMedia } from "next-seo/lib/types"
import React, { FC } from "react"

type ContentPageSeoProps = NextSeoProps & SanitySeo

export const ContentPageSeo: FC<ContentPageSeoProps> = ({
  title,
  addTitleSuffix,
  description,
  image,
  noindex,
  nofollow,
  openGraph = {},
}) => {
  const titleWithSuffix = `${title}${addTitleSuffix ? " - Viesus Cloud" : ""}`
  let images: OpenGraphMedia[] = []
  if (typeof image === "string") {
    images = [{ url: image }]
  }
  if (typeof image === "object") {
    images = [
      {
        url: urlFor(image.asset).width(1200).height(630).auto("format").url(),
        width: 1200,
        height: 630,
      },
    ]
  }
  return (
    <NextSeo
      title={titleWithSuffix}
      noindex={noindex}
      nofollow={nofollow}
      description={description}
      openGraph={{
        title: titleWithSuffix,
        description,
        images,
        ...openGraph,
      }}
    />
  )
}
