import { parse, parseAll, Theme } from "@config/theme";
import { css, cx } from "@linaria/core";
import { rem } from "polished";
import React, { forwardRef, HTMLAttributes } from "react";
import { ThemeSystemProps } from "theme-system";
export type ContainerSize = "small" | "medium" | "big";
type ContainerProps = Pick<ThemeSystemProps<Theme>, "mb" | "mt"> & HTMLAttributes<HTMLDivElement> & {
  as?: "div" | "section";
  size?: ContainerSize;
};
export const Container = forwardRef<ContainerProps, any>((props, ref) => {
  const {
    mb,
    mt,
    className,
    as = "section",
    size = "medium",
    ...rest
  } = props;
  const Comp = as;
  return <Comp className={cx(className, container, parseAll({
    mb,
    mt
  }))} data-size={size} {...rest} ref={ref}></Comp>;
});
Container.displayName = "Container";
const container = parse({
  position: "relative",
  mx: "auto"
}, "cgu0076");

require("./Container.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Container.tsx");