import { Span } from "@components/ui/typograhpy/Span"
import { parse } from "@config/theme"
import NextLink from "next/link"
import React, { FC } from "react"
import { FooterSingleton } from "__generated__/sanity"

type ContentPageFooterLinkProps = FooterSingleton["linksFirstColumn"]["0"]

export const ContentPageFooterLink: FC<ContentPageFooterLinkProps> = ({
  text,
  href,
  type,
}) => {
  const isExternal = type === "external"

  return (
    <>
      {isExternal ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={link}
        >
          <Span variant="button-label-big">{text}</Span>
        </a>
      ) : (
        <NextLink href={href} passHref>
          <Span variant="button-label-big" as="a" className={link}>
            {text}
          </Span>
        </NextLink>
      )}
    </>
  )
}

const link = parse({
  display: "block",
  mb: "16",
})
