import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rgba } from "polished";
export const container = parse({
  py: "16",
  display: "grid",
  justifyContent: {
    _: "space-between"
  }
}, "czoq7fy");
export const parent = parse({
  position: "fixed",
  width: "100%"
}, "pgicyxs");
export const spacer = parse({
  position: "relative",
  width: "100%"
}, "syv3qim");
export const logo = parse({
  position: "relative",
  py: "8"
}, "lczky9d");
export const desktopNav = parse({
  display: {
    _: "none",
    large: "flex"
  },
  alignItems: "center"
}, "dlido6r");
export const buttons = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end"
}, "bhkeu5s");

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");