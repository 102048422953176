import { parseAll, Theme } from "@config/theme"
import { cx } from "@linaria/core"
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
} from "react"
import { ThemeSystemProps } from "theme-system"
import IconChevronRightFat from "../icons/IconChevronRightFat"
import IconExternalFat from "../icons/IconExternalFat"
import { Heading, HeadingProps } from "../typograhpy/Heading"
import * as styles from "./styles"

type DefaultProps = {
  size: HeadingProps["variant"]
  color: "gradient" | "grey"
  variant: "internal" | "external"
}

type LinkVariant = DefaultProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
    as?: "link"
  }

type ButtonVariant = DefaultProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> & {
    as?: "button"
  }
type SpanVariant = DefaultProps &
  Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
    as?: "span"
  }

type HeadingLinkType = LinkVariant | ButtonVariant | SpanVariant

type HeadingLinkProps = Pick<ThemeSystemProps<Theme>, "mb" | "mt"> &
  HeadingLinkType

export const HeadingLink = forwardRef<any, HeadingLinkProps>((props, ref) => {
  const {
    as = "link",
    className,
    mb,
    mt,
    size,
    variant,
    color,
    children,
    ...rest
  } = props
  const iconSize = getIconSize(size)
  const Component = as === "link" ? "a" : as === "span" ? "span" : "button"
  return (
    // @ts-ignore
    <Component
      className={cx(styles.link, className, parseAll({ mb, mt }))}
      ref={ref}
      data-size={size}
      data-color={color}
      data-variant={variant}
      {...rest}
    >
      <Heading as="span" variant={size} data-text color="inherit">
        {children}
      </Heading>
      <div className={styles.icon} data-icon>
        {variant === "external" ? (
          <IconExternalFat size={iconSize} />
        ) : (
          <IconChevronRightFat size={iconSize} />
        )}
      </div>
    </Component>
  )
})

function getIconSize(size: HeadingProps["variant"]): number {
  switch (size) {
    case "h1": {
      return 48
    }
    case "h2": {
      return 40
    }
    case "h3": {
      return 32
    }
    case "h4": {
      return 24
    }
    case "h5": {
      return 24
    }
    default:
      return 16
  }
}

HeadingLink.displayName = "HeadingLink"
